import { wei } from '@synthetixio/wei'
import { Row } from '@tanstack/react-table'

export const weiSortingFn =
	<T extends Record<string, any>>(key: keyof T, subKey: string | null = null) =>
	(rowA: Row<T>, rowB: Row<T>) => {
		const valA = subKey ? rowA.original[key][subKey] : rowA.original[key]
		const valB = subKey ? rowB.original[key][subKey] : rowB.original[key]

		const rowOne = valA ? wei(valA) : wei(0)
		const rowTwo = valB ? wei(valB) : wei(0)

		return rowOne.toNumber() > rowTwo.toNumber() ? 1 : -1
	}
export const alphabeticalSortingFn =
	<T extends Record<string, any>>(key: keyof T, subKey: string | null = null) =>
	(rowA: Row<T>, rowB: Row<T>) => {
		const valA: string = subKey ? rowA.original[key][subKey] : rowA.original[key]
		const valB: string = subKey ? rowB.original[key][subKey] : rowB.original[key]

		return valA > valB ? 1 : -1
	}
export const durationSortingFn =
	<T extends Record<string, any>>(key: keyof T, subKey: string | null = null) =>
	(rowA: Row<T>, rowB: Row<T>) => {
		const valA: string = subKey ? rowA.original[key][subKey] : rowA.original[key]
		const valB: string = subKey ? rowB.original[key][subKey] : rowB.original[key]

		const parseDuration = (duration: string) => {
			const [days, hours] = duration?.split('d:')
			return { days: parseInt(days, 10), hours: parseInt(hours.replace('h', ''), 10) }
		}

		const timeA = parseDuration(valA)
		const timeB = parseDuration(valB)

		if (timeA.days === timeB.days) {
			// If days are the same, compare the hours
			return timeA.hours - timeB.hours
		}
		return timeA.days - timeB.days
	}
