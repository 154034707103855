import React from 'react'

export default function ShareIcon() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13 6a3 3 0 1 0-2.977-2.63l-4.94 2.47a3 3 0 1 0 0 4.319l4.94 2.47a3 3 0 1 0 .895-1.789l-4.94-2.47c.03-.246.03-.494 0-.74l4.94-2.47C11.456 5.68 12.19 6 13 6z"
				fill="#C8C7D8"
			></path>
		</svg>
	)
}
