import { FC, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { FuturesMarginType } from '@dextoroprotocol/sdk/types'
import { FuturesPositionTablePositionActive } from 'types/futures'

import { useAppSelector } from 'state/hooks'
import { selectMarkPrices } from 'state/futures/selectors'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { AppFuturesMarginType } from 'state/futures/common/types'
import { selectCrossMarginActivePositions } from 'state/futures/crossMargin/selectors'
import { selectSmartMarginActivePositions } from 'state/futures/smartMargin/selectors'

import { Body } from 'components/Text'
import ShareIcon from 'components/Icons/ShareIcon'
import { FlexDivRowCentered } from 'components/layout/flex'
import { getSynthDescription } from 'utils/futures'
import media from 'styles/media'

import OverviewPositionTable from './OverviewPositionTable'
import { SharePositionTable } from 'sections/futures/ShareModal'

type FuturesPositionTableProps = {
	accountType: AppFuturesMarginType
	showCurrentMarket?: boolean
	showEmptyTable?: boolean
}

const FuturesPositionsTable: FC<FuturesPositionTableProps> = ({ accountType }) => {
	const { t } = useTranslation()
	const currentTheme = useAppSelector(selectCurrentTheme)

	const [showShareModal, setShowShareModal] = useState(false)
	const [sharePosition, setSharePosition] = useState<FuturesPositionTablePositionActive[] | []>([])

	const crossMarginPositions = useAppSelector(selectCrossMarginActivePositions)
	const smartMarginPositions = useAppSelector(selectSmartMarginActivePositions)
	const pricesInfo = useAppSelector(selectOffchainPricesInfo)
	const markPrices = useAppSelector(selectMarkPrices)

	let data = useMemo(() => {
		const positions =
			accountType === FuturesMarginType.SMART_MARGIN ? smartMarginPositions : crossMarginPositions
		return positions.map((position) => {
			const description = getSynthDescription(position.market.asset, t)
			const priceInfo = pricesInfo[position.market.asset]
			const marketPrice = markPrices[position.market.marketKey] ?? ZERO_WEI
			return {
				...position,
				description,
				marketPrice,
				priceInfo,
			}
		})
	}, [accountType, crossMarginPositions, markPrices, pricesInfo, smartMarginPositions, t])

	const handleOpenShareModal = useCallback((share: FuturesPositionTablePositionActive[]) => {
		setSharePosition(share)
		setShowShareModal((s) => !s)
	}, [])

	return (
		<Container>
			<TitleContainer>
				<Body size="large" color="primary" weight="bold" fontSize={20}>
					{t('dashboard.titles.position')}
				</Body>
				{data.length > 0 ? (
					<ShareButton onClick={() => handleOpenShareModal(data)} currentTheme={currentTheme}>
						<ShareIcon /> Share
					</ShareButton>
				) : (
					<></>
				)}
			</TitleContainer>
			<OverviewPositionTable accountType={FuturesMarginType.SMART_MARGIN} />
			{showShareModal && (
				<SharePositionTable
					sharePositionTable={sharePosition!}
					setShowShareModal={setShowShareModal}
				/>
			)}
		</Container>
	)
}

const Container = styled.div`
	${media.lessThan('xxl')`
	  margin: auto 12px;
  `}
`

const TitleContainer = styled(FlexDivRowCentered)`
	gap: 1em;
	padding: 20px 16px;
	max-width: calc(var(1280px) + var(324px));
`

const ShareButton = styled.button<{ currentTheme: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	font-size: 14px;
	line-height: 18px;
	min-height: 36px;
	height: 36px;
	border: none;
	user-select: none;
	white-space: nowrap;
	cursor: pointer;
	border-radius: 8px;
	padding: 0 16px;
	font-feature-settings: 'zero' 0;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.button.secondary.text};
	background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};

	& > svg {
		width: 14px;
		margin-right: 8px;
		path {
			fill: currentColor;
		}
	}

	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.2)' : 'brightness(0.9)')};
	}
`

export default FuturesPositionsTable
