import { FC, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { FuturesMarginType, PositionSide } from '@dextoroprotocol/sdk/types'
import { formatDollars } from '@dextoroprotocol/sdk/utils'
import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { NO_VALUE } from 'constants/placeholder'
import ROUTES from 'constants/routes'

import useIsL2 from 'hooks/useIsL2'
import Connector from 'containers/Connector'
import { useAppSelector } from 'state/hooks'
import useNetworkSwitcher from 'hooks/useNetworkSwitcher'
import { selectMarkPrices } from 'state/futures/selectors'
import { AppFuturesMarginType } from 'state/futures/common/types'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { selectCrossMarginActivePositions } from 'state/futures/crossMargin/selectors'
import { selectSmartMarginActivePositions } from 'state/futures/smartMargin/selectors'

import Currency from 'components/Currency'
import MarketBadge from 'components/MarketBadge'
import ColoredPrice from 'components/ColoredPrice'
import { Body, NumericValue } from 'components/Text'
import ChangePercent from 'components/ChangePercent'
import Table, { TableCellHead, TableNoResults } from 'components/Table'
import { BaseTableBodyRow, TableCell } from 'components/Table/TableBodyRow'
import { DesktopLargeOnlyView, DesktopSmallOnlyView } from 'components/Media'
import PositionType from 'sections/futures/PositionType'

import { getSynthDescription } from 'utils/futures'
import { convertMarketName } from 'utils/formatters/marketName'
import { weiSortingFn, alphabeticalSortingFn } from 'utils/table'
import media from 'styles/media'
import NoDataIcon from 'assets/svg/app/no-positions.svg'

type FuturesPositionTableProps = {
	accountType: AppFuturesMarginType
	showCurrentMarket?: boolean
	showEmptyTable?: boolean
}

const sortBy = [{ id: 'market', desc: true }]

const OverviewPositionTable: FC<FuturesPositionTableProps> = ({
	accountType,
	showCurrentMarket = true,
}) => {
	const router = useRouter()
	const { t } = useTranslation()

	const { switchToL2 } = useNetworkSwitcher()
	const { isWalletConnected } = Connector.useContainer()
	const isL2 = useIsL2()

	const { openConnectModal: connectWallet } = useConnectModal()
	const crossMarginPositions = useAppSelector(selectCrossMarginActivePositions)
	const smartMarginPositions = useAppSelector(selectSmartMarginActivePositions)
	const pricesInfo = useAppSelector(selectOffchainPricesInfo)
	const markPrices = useAppSelector(selectMarkPrices)

	let data = useMemo(() => {
		const positions =
			accountType === FuturesMarginType.SMART_MARGIN ? smartMarginPositions : crossMarginPositions
		return positions.map((position) => {
			const description = getSynthDescription(position.market.asset, t)
			const priceInfo = pricesInfo[position.market.asset]
			const marketPrice = markPrices[position.market.marketKey] ?? ZERO_WEI
			return {
				...position,
				description,
				marketPrice,
				priceInfo,
			}
		})
	}, [accountType, crossMarginPositions, markPrices, pricesInfo, smartMarginPositions, t])

	const showTable = useMemo(() => (data.length > 0 ? true : false), [data])
	if (!showTable)
		return (
			<EmptyTableContainer>
				{!isL2 ? (
					<>{t('dashboard.overview.futures-positions-table.wrong-network')}</>
				) : (
					<>
						<NoDataIcon />
						{t('futures.market.trade.button.no-open-postion-para')}
					</>
				)}
			</EmptyTableContainer>
		)

	return (
		<>
			<DesktopLargeOnlyView>
				<DesktopTable
					data={data}
					columnVisibility={{ 'tp-sl': accountType === FuturesMarginType.SMART_MARGIN }}
					onTableRowClick={(row) =>
						router.push(ROUTES.Markets.MarketPair(row.original.market.asset, accountType))
					}
					noResultsMessage={
						!isL2 ? (
							<TableNoResults>
								{t('common.l2-cta')}
								{isWalletConnected ? (
									<div onClick={switchToL2}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
								) : (
									<div onClick={connectWallet}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
								)}
							</TableNoResults>
						) : (
							<TableNoResults>
								{!showCurrentMarket ? (
									t('dashboard.overview.futures-positions-table.no-result')
								) : (
									<>{t('common.perp-cta')}</>
								)}
							</TableNoResults>
						)
					}
					highlightRowsOnHover
					overviewPositionTable
					columns={[
						{
							header: () => (
								<TableHeader>{t('dashboard.overview.futures-positions-table.market')}</TableHeader>
							),
							id: 'market',
							accessorKey: 'market',
							cell: (cellProps) => {
								return (
									<MarketContainer>
										<IconContainer>
											{/* <Currency.Icon
												width={30}
												height={30}
												currencyKey={cellProps.row.original.market.marketKey}
											/> */}
										</IconContainer>
										<CellContainer>
											<StyledText>
												{convertMarketName(cellProps.row.original.market.marketName)}
												<MarketBadge
													currencyKey={cellProps.row.original.market.marketKey}
													isFuturesMarketClosed={cellProps.row.original.market.isSuspended}
													futuresClosureReason={cellProps.row.original.market.marketClosureReason}
												/>
											</StyledText>
											<StyledValue>
												<ColoredPrice priceChange={cellProps.row.original.priceInfo?.change} mono>
													{formatDollars(cellProps.row.original.marketPrice, {
														suggestDecimalsForAsset: cellProps.row.original.market.asset,
													})}
												</ColoredPrice>
											</StyledValue>
										</CellContainer>
									</MarketContainer>
								)
							},
							size: 180,
							enableSorting: true,
							sortingFn: weiSortingFn('marketPrice'),
						},
						{
							header: () => (
								<TableHeader>{t('dashboard.overview.futures-positions-table.side')}</TableHeader>
							),
							id: 'position',
							accessorKey: 'position',
							cell: (cellProps) => {
								return <PositionType side={cellProps.row.original.activePosition.side} />
							},
							size: 70,
							enableSorting: true,
							sortingFn: alphabeticalSortingFn('activePosition', 'side'),
						},
						{
							header: () => (
								<TableHeader>
									{t('dashboard.overview.futures-positions-table.notionalValue')}
								</TableHeader>
							),
							id: 'notionalValue',
							accessorKey: 'notionalValue',
							cell: (cellProps) => {
								return (
									<Currency.Price
										mono
										price={cellProps.row.original.activePosition.notionalValue}
										formatOptions={{ truncateOver: 1e6 }}
										fontSize={14}
									/>
								)
							},
							size: 90,
							enableSorting: true,
							sortingFn: weiSortingFn('activePosition', 'notionalValue'),
						},
						{
							header: () => (
								<TableHeader>
									{t('dashboard.overview.futures-positions-table.avg-entry')}
								</TableHeader>
							),
							id: 'avgEntryPrice',
							accessorKey: 'avgEntryPrice',
							cell: (cellProps) => {
								return cellProps.row.original.activePosition.details?.avgEntryPrice ===
									undefined ? (
									<Body>{NO_VALUE}</Body>
								) : (
									<CellContainer>
										<Currency.Price
											price={cellProps.row.original.activePosition.details?.avgEntryPrice}
											formatOptions={{ suggestDecimals: true }}
											fontSize={14}
											mono
										/>
										<Currency.Price
											price={cellProps.row.original.activePosition.liquidationPrice}
											formatOptions={{ suggestDecimals: true }}
											colorType="secondary"
											fontSize={14}
											mono
										/>
									</CellContainer>
								)
							},
							size: 125,
							enableSorting: true,
							sortingFn: weiSortingFn('activePosition', 'liquidationPrice'),
						},
						{
							header: () => (
								<TableHeader>{t('dashboard.overview.futures-positions-table.pnl')}</TableHeader>
							),
							id: 'pnl',
							accessorKey: 'pnl',
							cell: (cellProps) => {
								return (
									<CellContainer>
										<ChangePercent
											value={cellProps.row.original.activePosition.pnlPct}
											fontSize={14}
											mono
										/>
										<Currency.Price
											price={cellProps.row.original.activePosition.pnl}
											fontSize={14}
											colored
											mono
										/>
									</CellContainer>
								)
							},
							size: 125,
							enableSorting: true,
							sortingFn: weiSortingFn('activePosition', 'pnl'),
						},
						{
							header: () => <TableHeader>TP/SL</TableHeader>,
							id: 'tp-sl',
							accessorKey: 'tp-sl',
							cell: (cellProps) => {
								return (
									<CellContainer>
										{cellProps.row.original.takeProfit === undefined ? (
											<Body mono>{NO_VALUE}</Body>
										) : (
											<Currency.Price
												price={cellProps.row.original.takeProfit.targetPrice}
												formatOptions={{ suggestDecimals: true }}
												fontSize={14}
												mono
											/>
										)}
										{cellProps.row.original.stopLoss === undefined ? (
											<Body mono>{NO_VALUE}</Body>
										) : (
											<Currency.Price
												price={cellProps.row.original.stopLoss.targetPrice}
												formatOptions={{ suggestDecimals: true }}
												fontSize={14}
												mono
											/>
										)}
									</CellContainer>
								)
							},
							size: 90,
							enableSorting: true,
							sortingFn: weiSortingFn('stopLoss'),
						},
						{
							header: () => <TableHeader>Market Margin</TableHeader>,
							id: 'margin',
							accessorKey: 'margin',
							cell: (cellProps) => {
								return (
									<CellContainer>
										<NumericValue
											value={cellProps.row.original.activePosition.initialMargin}
											fontSize={14}
											mono
										/>
										<NumericValue
											value={cellProps.row.original.activePosition.leverage}
											color="secondary"
											fontSize={14}
											suffix="x"
											mono
										/>
									</CellContainer>
								)
							},
							size: 115,
							enableSorting: true,
							sortingFn: weiSortingFn('activePosition', 'initialMargin'),
						},
					]}
				/>
			</DesktopLargeOnlyView>
			<DesktopSmallOnlyView>
				<MobileTable
					data={data}
					columnVisibility={{ 'tp-sl': accountType === FuturesMarginType.SMART_MARGIN }}
					onTableRowClick={(row) =>
						router.push(ROUTES.Markets.MarketPair(row.original.market.asset, accountType))
					}
					noResultsMessage={
						!isL2 ? (
							<TableNoResults>
								{t('common.l2-cta')}
								{isWalletConnected ? (
									<div onClick={switchToL2}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
								) : (
									<div onClick={connectWallet}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
								)}
							</TableNoResults>
						) : (
							<TableNoResults>
								{!showCurrentMarket ? (
									t('dashboard.overview.futures-positions-table.no-result')
								) : (
									<>{t('common.perp-cta')}</>
								)}
							</TableNoResults>
						)
					}
					highlightRowsOnHover
					overviewPositionTable
					columns={[
						{
							header: () => (
								<TableHeaderMobile>
									{t('dashboard.overview.futures-positions-table.market')}
								</TableHeaderMobile>
							),
							id: 'market',
							accessorKey: 'market',
							cell: (cellProps) => {
								return (
									<MarketContainer>
										<IconContainer>
											{/* <Currency.Icon
												currencyKey={cellProps.row.original.market.marketKey}
												width={30}
												height={30}
											/> */}
										</IconContainer>
										<CellContainer>
											<StyledText>
												{convertMarketName(cellProps.row.original.market.marketName)}
												<MarketBadge
													currencyKey={cellProps.row.original.market.marketKey}
													isFuturesMarketClosed={cellProps.row.original.market.isSuspended}
													futuresClosureReason={cellProps.row.original.market.marketClosureReason}
												/>
											</StyledText>
											<StyledValue>
												<ColoredPrice
													priceChange={cellProps.row.original.priceInfo?.change}
													fontSize={12}
													mono
												>
													{formatDollars(cellProps.row.original.marketPrice, {
														suggestDecimalsForAsset: cellProps.row.original.market.asset,
													})}
												</ColoredPrice>
											</StyledValue>
										</CellContainer>
									</MarketContainer>
								)
							},
							size: 120,
							enableSorting: true,
						},
						{
							header: () => (
								<TableHeaderMobile>
									{t('dashboard.overview.futures-positions-table.side')}
								</TableHeaderMobile>
							),
							id: 'position',
							accessorKey: 'position',
							cell: (cellProps) => {
								return <PositionType side={cellProps.row.original.activePosition.side} />
							},
							size: 80,
							enableSorting: true,
						},
						{
							header: () => (
								<TableHeaderMobile>
									{t('dashboard.overview.futures-positions-table.pnl')}
								</TableHeaderMobile>
							),
							id: 'pnl',
							accessorKey: 'pnl',
							cell: (cellProps) => {
								return (
									<CellContainer style={{ alignItems: 'flex-end' }}>
										<ChangePercent
											value={cellProps.row.original.activePosition.pnlPct}
											fontSize={12}
											mono
										/>
										<Currency.Price
											price={cellProps.row.original.activePosition.pnl}
											fontSize={12}
											colored
											mono
										/>
									</CellContainer>
								)
							},
							size: 80,
							enableSorting: true,
							sortingFn: weiSortingFn('activePosition', 'pnl'),
						},
						{
							header: () => <TableHeaderMobile>Market Margin</TableHeaderMobile>,
							id: 'margin',
							accessorKey: 'margin',
							cell: (cellProps) => {
								return (
									<CellContainer style={{ alignItems: 'flex-end' }}>
										<NumericValue
											value={cellProps.row.original.activePosition.initialMargin}
											fontSize={12}
											mono
										/>
										<NumericValue
											value={cellProps.row.original.activePosition.leverage}
											color="secondary"
											fontSize={12}
											suffix="x"
											mono
										/>
									</CellContainer>
								)
							},
							size: 120,
							enableSorting: true,
							sortingFn: weiSortingFn('activePosition', 'initialMargin'),
						},
					]}
				/>
			</DesktopSmallOnlyView>
		</>
	)
}

const DesktopTable = styled(Table)`
	border: none;
	${TableCellHead} {
		border: none;
		background: none;
		margin-bottom: 4px;
		color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
		&:first-child {
			padding-left: 20px;
		}
		&:last-child {
			padding-right: 20px;
		}
	}

	${BaseTableBodyRow} {
		display: flex;
		border: none;
		height: 64px;
		min-height: 100%;
		margin-bottom: 12px;
		border-radius: 12px;
		align-items: center;
		position: relative;
	}
` as typeof Table

const MarketContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const CellContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2px;
`

const IconContainer = styled.div`
	grid-column: 1;
	grid-row: 1 / span 2;
	margin-right: 8px;
`

const StyledText = styled.div`
	display: flex;
	grid-row: 1;
	grid-column: 2;
	font-size: 14px;
	align-items: center;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
`

const StyledValue = styled.div`
	grid-row: 2;
	grid-column: 2;
	font-size: 12px;
	font-family: ${(props) => props.theme.fonts.regular};
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`

const TableHeader = styled(Body)`
	letter-spacing: 0;
	color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};
	}
`

const TableHeaderMobile = styled(TableHeader)`
	font-size: 12px;
`

const MobileTable = styled(Table)`
	border: none;
	${TableCellHead} {
		border: none;
		background: none;
		margin-bottom: 4px;
		color: ${(props) => props.theme.colors.selectedTheme.primaryWhite};
		&:nth-child(3) {
			padding-right: 8px !important;
		}
		&:last-child {
			padding-right: 8px !important;
		}
	}
	${TableCell} {
		justify-content: center;
		&:first-child {
			padding-left: 10px;
			justify-content: flex-start;
		}
		&:nth-child(3) {
			justify-content: flex-end;
		}
		&:last-child {
			padding-right: 24px;
			justify-content: flex-end;
		}
	}
	${BaseTableBodyRow} {
		display: flex;
		border: none;
		height: 64px;
		min-height: 100%;
		margin-bottom: 12px;
		border-radius: 12px;
		align-items: center;
		position: relative;
	}
` as typeof Table

const EmptyTableContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: ${(props) => props.theme.colors.selectedTheme.border};
	color: ${(props) => props.theme.colors.selectedTheme.text.body};
	background: ${(props) => props.theme.colors.selectedTheme.table.fill};
	border-radius: 12px;
	font-size: 15px;
	padding: 4rem;
	height: 100%;
	margin: auto;
	svg {
		width: 45px;
		height: 45px;
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.newTheme.text.secondary};
		}
		margin-bottom: 16px;
	}
	${media.lessThan('xxl')`
		border-radius: 0;
		border-left: none;
		border-right: none;
	  margin: auto -12px;
  `}
`

export default OverviewPositionTable
